<template>
    <div class="container">
        <qy-top-nav title="我的售后" />
        <van-dropdown-menu>
            <van-dropdown-item v-model="goodsStatus" :options="goodsStatusList"  @change="handleChangeGoodsStatus"/>
            <van-dropdown-item title="筛选·" ref="item">
<!--            <van-cell center title="所有时间" value="" is-link />-->
                <van-cell center title="开始时间" :value="beginTime" is-link @click="show = true,select = '1'"/>
                <van-cell center title="结束时间" :value="endTime" is-link @click="show = true,select = '2'"/>
                <van-calendar v-model="show" @confirm="onConfirm" :min-date="minDate" :max-date="maxDate"/>
                <br>
                <div style="text-align: center;padding: 0px 0px 10px 0px;">
                    <van-button type="info"   size="normal" @click="queryTime" style="border-radius: 5px;"><van-icon name="success" /> 确认</van-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <van-button type="danger" size="normal" @click="close"   style="border-radius: 5px;"><van-icon name="cross" /> 清除</van-button>
                </div>
            </van-dropdown-item>
        </van-dropdown-menu>
        <!--我的售后-->
        <div :style="{height: containerHeight,overflow:'auto'}" >
            <van-list
                v-model="loading"
                :finished="finished"
                :offset="offset"
                finished-text="没有更多了"
                @load="onLoad"
            >
                <van-swipe-cell v-for="item in dataList">
                        <div class="listDetails">
                            <div class="shopTitle">
                                <van-icon name="shop-o" size="1.5rem" style="position: relative;top:7px;"/>
                                <span style="font-size: 1.2rem;font-weight: bold;">{{item.shopName}}</span>
                                <span style="float:right;color:orange;margin-right: 1.2rem;font-weight: bold;font-size: 1.2rem;">
                                    {{statusDesc[item.status]}}
                                </span>
                            </div>
                            <div class="goods-info">
                                <van-image
                                    alt="商品logo"
                                    width="65"
                                    height="65"
                                    :src="getImgUrl(item.goodsLogo)"
                                />
                                <div class="block-inline name-block">
                                    <div class="goodsName">{{item.goodsName.length > 15 ? item.goodsName.substring(0,15).concat('...') : item.goodsName}}</div>
                                    <div class="comment"><label>处理时间：</label><span>{{item.createTime}}</span></div>
                                    <div class="comment"><label>联系方式：</label><span>{{item.name}}&nbsp;{{item.phone}}</span></div>
                                </div>
                            </div>
                            <div class="question">
                                <label>{{item.questionType}}：{{item.questionRemark}}</label>
                            </div>
                            <van-divider/>
                            <div class="upload-pinz">
                                <div class="comment" v-if="item.status == '1'">
                                    <label>处理结果：</label><span>{{item.processRemark}}</span>
                                </div>
                                <div class="comment">
                                    <label style="position: relative;top:-40px;">上传凭证：</label>
                                    <van-image
                                            v-for="img in item.questionImgs"
                                            width="80"
                                            height="55"
                                            :src="getImgUrl(img)"
                                            style="margin-right: 3px;"
                                    />
                                </div>
                            </div>
                        </div>
                        <template #right v-if="item.status == '0'">
                            <van-button square type="danger" text="删除" style="height: 100%" @click="deleteAfterSale(item.id)"/>
                        </template>
                </van-swipe-cell>
            </van-list>
        </div>
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { Toast } from 'vant';
    export default {
        name: "myAfterSale",
        data() {
            return {
                minDate:"",
                maxDate:new Date(),
                containerHeight:0,
                show:false,
                loading:false,
                finished:false,
                offset:50,
                goodsStatus: "",
                goodsStatusList: [
                    { text: "全部", value: "" },
                    { text: "未处理", value: "0" },
                    { text: "已处理", value: "1" }
                ],
                statusDesc: {
                    0: "未处理",
                    1: "已处理",
                },
                select:'',
                beginTime:'',
                endTime:'',
                dataList:[],
                pageSize: 10,
                pageNum: 1,
            }
        },
        created(){
            this.minDate = new Date(1975,1,1);
            let currentDate = new Date();
            this.beginTime = this.formatDate(new Date(currentDate.getFullYear(),currentDate.getMonth(),1));
            this.endTime = this.formatDate(currentDate);
        },
        methods:{
            close(){
                this.beginTime = '';
                this.endTime = '';
            },
            queryTime(){
                this.pageNum = 1;
                this.dataList = [];
                this.doRequestAfterSale();
                this.$refs.item.toggle();
            },
            onConfirm(date) {
                this.show = false;
                switch (this.select) {
                    case '1':
                        this.beginTime = this.formatDate(date);
                        break;
                    case '2':
                        this.endTime = this.formatDate(date);
                        break;
                    default:
                        this.beginTime = '';
                        this.endTime = '';
                        break;
                }
            },
            formatDate(date) {
                return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            },
            handleChangeGoodsStatus(v){
                this.pageNum = 1;
                this.dataList = [];
                this.loading =false;
                this.doRequestAfterSale();
            },
            onLoad(){
                let isLogin = this.isLogin();
                if(!isLogin){
                    this.$router.push("/login");
                    return
                }
                if(!this.finished){
                    this.doRequestAfterSale();
                }
            },
            deleteAfterSale(id){
                request.post("/api/saleAfter/delete/"+id).then(
                    res => {
                        if (res.success){
                            this.dataList = this.dataList.filter(item=>{
                                return item.id != id;
                            });
                            this.Toast.success("删除成功");
                        }else {
                            this.Toast.fail(res.msg);
                        }
                    }
                );
            },
            doRequestAfterSale(){
                let that = this;
                const db = {
                    pageNum:that.pageNum,
                    pageSize:that.pageSize,
                    beginTime:that.beginTime,
                    endTime:that.endTime,
                    status:that.goodsStatus,
                }
                request.post("/api/saleAfter/list",db).then(res => {
                    if(res.success){
                        let data = res.data;
                        let hasNextPage = data.hasNextPage;
                        let list = data.list;
                        list.forEach(item => {
                            if(item.questionImg){
                                item.questionImgs = item.questionImg.split(',');
                                delete item.commentImg;
                            }else {
                                item.questionImgs = [];
                            }
                        });
                        if (list.length > 0){
                            this.dataList = this.dataList.concat(list);
                        }
                        if(!hasNextPage){
                            that.finished = true;
                            that.loading = false;
                        }else {
                            that.finished = false;
                            that.loading = false;
                            that.pageNum++;
                        }
                    }else {
                        that.finished = true;
                    }
                });
            }
        },
        mounted() {
            this.containerHeight = document.documentElement.clientHeight - 100 + "px";
        }
    }
</script>

<style scoped>
    div.container{
        margin: 0px;
        padding: 0px;
        font-size: 14px;
        background-color:#f5f5f5;
    }
    .listDetails {
        padding-left: 30px;
        align-items: center;
        background-color: #ffffff;
        border-radius: 5px;
        margin: 5px 5px;
    }
    .shopTitle {
        height: 50px;
        line-height: 45px;
    }
    .shopTitle .van-checkbox {
        display: inline-block;
        margin-left: 10px;
        position: relative;
        top: 4px;
    }
    .comment{
        padding: 1.5px;
    }
    .comment-goods img{
        width: 50px;
        height: 50px;
    }
    .img-block{
        width: 20%;
    }
    .name-block{
        width: 55%;
    }
    .time-block{
        width: 25%;
    }
    .block-inline{
        height: auto;
        display: inline-block;
        overflow: hidden;
        padding: 0 5px;
    }
    .block-inline>.goodsName{
        font-size: 16px;
        font-weight: bold;
    }
    .comment>label{
        color: #6d6d6d;
    }
    .question{
        margin-right: 15px;
        border-radius: 3px;
        background-color: #f5f5f5;
        padding: 8px;
    }
    .comment-imgs img{
        width: 85px;
        height: 65px;
        padding: 5px 5px;
    }
    .comment-level span{
        position: relative;
        left:15px;
    }
    div.upload-pinz{
        margin-top:-6px;
    }
	.van-calendar__day{height:30px!important;}
	.van-calendar__selected-day{width:30px!important;height:30px;}
	.van-button--info{background:none;color:#ee0a24;border-color:#ee0a24;line-height:35px;height:35px;}
	.van-button--danger{line-height:35px;height:35px;}
</style>